vue
<template>
  <div>
    <div class="p-grid">
      <div class="p-col-12 p-d-flex p-jc-center">
        <p class="title-bold">Micropigmentación</p>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col-12 p-d-flex p-jc-center">
        <img class="img-fluid" alt="microblading" src="../../assets/esteticaMicroblading.jpg" />
      </div>
      <div class="p-col-12 p-d-flex p-jc-center">
        <img class="img-fluid" alt="micropigmentacion labios" src="../../assets/esteticaMicropigmentacionDeLabios.jpg" />
      </div>

      <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
        <Card styleClass="p-card-shadow">
          <template #content>

            <p>
              La <strong>Micropigmentación</strong> es una técnica de maquillaje semipermanente cuyo
              proceso lleva consigo la implantación de pigmento en la epidermis con
              resultados muy naturales e hiperrealistas en cejas, línea de ojos,
              labios, Areolas y capilar.
            </p>

            <ol>
              <li>
                En primer lugar se realiza un diseño personalizado o visagismo y
                prueba una de alergia al pigmento y al anestésico (cuatro o cinco días
                antes del tratamiento).
              </li>
              <li>
                Se realiza el procedimiento que tiene una duración aproximada de una
                hora y media.
              </li>
              <li>
                Tras el procedimiento: Durante la primera semana se producirá un
                aumento del tono elegido. Desde el décimo día hasta el 25 del mismo
                mes, se estabilizará el color.
              </li>
              <li>
                Pasado un mes, se retocará el diseño original puesto que parte de los
                pigmentos aplicados la primera vez son absorbidos y el color se atenúa
                un poco.
              </li>
              <li>
                Pasado un año se recomienda un retoque que dependerá del tipo de piel;
                grasa o mixta y de los cuidados post-tratamiento para que se realice
                antes o después del año.
              </li>
            </ol>

            <p class="p-text-bold">Micropigmentación de labios</p>
            <p>
              Esta técnica de maquillaje semipermanente de labios con un color de
              aspecto muy natural. El resultado son unos labios «rellenos»,
              perfilados, perfectos y como recién pintados.
            </p>

            <p class="p-text-bold">Microblading de cejas</p>
            <p>
              El microblading es un actual proceso estético que ofrece la posibilidad
              de tener unas cejas perfectas a través la técnica denominada pelo a
              pelo, permite cambiar la forma y el color de la ceja. Está sugerido para
              clientes que, por algún motivo, quieran acrecentar el volumen de sus
              cejas de la manera más natural posible.
            </p>

            <p class="p-text-bold">Micropigmentación de ojos</p>
            <p>
              Consiste en hacer una línea en los ojos o eyeliner (delineado de ojos)
              por encima y si se desea también por debajo de los párpados. Se pueden
              realizar dos técnicas; una es la del punteado, que se utiliza cuando tan
              sólo se quiere conseguir un efecto óptico de relleno de pestañas sin que
              parezca que va maquillado, se puede hacer tanto a hombres como mujeres.
            </p>

            <p><strong>La micropigmentación capilar</strong> es un procedimiento no quirúrgico que utiliza
              pigmentos específicos para simular la apariencia de folículos capilares en zonas donde hay
              pérdida de cabello. Se aplica mediante microagujas que depositan pigmento en la piel, creando la
              ilusión de densidad capilar y un aspecto natural. Es una solución para disimular la calvicie o
              acentuar la densidad capilar en zonas con cabello fino. Los resultados son duraderos y requieren
              mantenimiento periódico.</p>

            <p>La micropigmentación capilar es un procedimiento mínimo y generalmente bien tolerado. Los
              pacientes suelen experimentar una sensación de pinchazo leve o ninguna molestia. La duración
              varía según el individuo, pero los resultados pueden durar de 2 a 5 años, dependiendo del estilo
              de vida y cuidado posterior.</p>

            <p>La <strong>micropigmentación en cejas efecto polvo</strong> es una técnica de embellecimiento
              semipermanente que busca lograr unas cejas densas y naturales. A diferencia de otras técnicas,
              esta crea una apariencia de sombreado suave y uniforme, similar al maquillaje en polvo. Se
              realiza mediante la inserción de pigmentos en la epidermis de la piel con agujas especiales,
              logrando un aspecto de cejas perfectamente definido. Es ideal para quienes desean cejas más
              llenas, con un acabado suave y sin trazos individuales visibles. Esta técnica de
              micropigmentación requiere retoques periódicos para mantener su frescura.</p>
          </template>

          <template #footer>
            <span>Para más información no dude en ponerse en contacto con nuestro
              Centro médico al 637 857 384.</span>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from 'primevue/card';
export default {
  name: 'MicropigmentacionVue',
  data() {
    return {
      // Propiedades de datos del componente
    };
  },
  components: {
    Card
  },
  methods: {
    // Métodos del componente
  },
  computed: {
    // Propiedades computadas del componente
  },
  created() {
    // Lógica que se ejecuta cuando el componente se crea
  },
  mounted() {
    // Lógica que se ejecuta cuando el componente se monta en el DOM
  },
  destroyed() {
    // Lógica que se ejecuta cuando el componente se destruye
  },
};
</script>

<style scoped>
.title-bold {
  font-size: 30px;
  font-weight: bold
}

.img-fluid {
  width: 100%;
  height: auto;
}

@media (min-width: 576px) {
  .img-fluid {
    max-width: 50%;
    height: auto;
  }
}
</style>